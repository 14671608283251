<template>
  <div class="dialog-container">
    <el-dialog
        width="600px"
        title="Xuất file thống kê"
        :visible.sync="showExportStatisticsFile"
        :before-close="handleCloseExportStatisticsFile"
        :close-on-click-modal="false"
    >
      <div>
        <el-radio-group v-model="selectExport">
          <el-radio :label="1" style="margin-top: 20px">Xuất danh sách các học sinh chưa hoàn thành hóa đơn</el-radio>
          <el-radio :label="2" style="margin-top: 30px">Xuất danh sách các học sinh đã hoàn thành hóa đơn</el-radio>
          <el-radio :label="3" style="margin-top: 30px">Xuất danh sách các học sinh chưa có hóa đơn</el-radio>
          <el-radio :label="4" style="margin-top: 30px">Xuất danh sách các học sinh với tất cả trạng thái</el-radio>
        </el-radio-group>
      </div>
      <div class="buttons">
        <el-button
            :loading="loadingExportFile"
            size="medium"
            type="success"
            @click="handleExportFile()"
        >
          OK
        </el-button>
        <el-button size="medium" type="danger" @click="handleCloseExportStatisticsFile">
          <i class="el-icon-circle-close"/> Đóng
        </el-button>
      </div>
    </el-dialog>
    <ExportStudentNotCompletedForGroup
        :showExportStudentNotCompleted="showExportStudentNotCompleted"
        :idGroupList = "idGroupList"
        :collectionTurn = "dataSearch.collectionTurn"
        @close="closeExportStudentNotCompleted"
        ref="ExportStudentNotCompletedForGroup"
    />
    <ExportStudentCompleteForGroup
        :showExportStudentComplete="showExportStudentComplete"
        :idGroupList = "idGroupList"
        :collectionTurn = "dataSearch.collectionTurn"
        @close="closeExportStudentComplete"
        ref="ExportStudentCompleteForGroup"
    />
    <ExportStudentNotOrderForGroup
        :showExportStudentNotOrder="showExportStudentNotOrder"
        :idGroupList = "idGroupList"
        :collectionTurn = "dataSearch.collectionTurn"
        @close="closeExportStudentNotOrder"
        ref="ExportStudentCompleteForGroup"
    />
    <ExportStudentCompleteAndNotForGroup
        :showExportStudentCompleteAndNot="showExportStudentCompleteAndNot"
        :idGroupList = "idGroupList"
        :collectionTurn = "dataSearch.collectionTurn"
        @close="closeExportStudentCompleteAndNot"
        ref="ExportStudentCompleteAndNotForGroup"
    />
  </div>
</template>

<script>
import ExportStudentNotCompletedForGroup
  from "@/components/FinanceVer2/TuitionStatistics/Group/TableSearchDialog/ExportStudentNotCompletedForGroup.vue";
import ExportStudentCompleteForGroup
  from "@/components/FinanceVer2/TuitionStatistics/Group/TableSearchDialog/ExportStudentCompleteForGroup.vue";
import ExportStudentNotOrderForGroup
  from "@/components/FinanceVer2/TuitionStatistics/Group/TableSearchDialog/ExportStudentNotOrderForGroupDialog.vue";
import statisticFinanceV2Service from "@/services/Finance_Kids_V2/StatisticFinanceV2Service";
import ExportStudentCompleteAndNotForGroup
  from "@/components/FinanceVer2/TuitionStatistics/Group/TableSearchDialog/ExportStudentCompleteAndNotForGroup.vue";

export default {
  name: "ExportStatisticsFileForGroup",
  components: {
    ExportStudentCompleteAndNotForGroup,
    ExportStudentNotOrderForGroup,
    ExportStudentCompleteForGroup,
    ExportStudentNotCompletedForGroup,
  },
  data() {
    return {
      selectExport: 1,
      loadingExportFile: false,
      showExportStudentNotCompleted: false,
      showExportStudentComplete: false,
      showExportStudentNotOrder: false,
      showExportStudentCompleteAndNot: false,
      listNameStudentNotInGroup: []
    }
  },
  props: {
    showExportStatisticsFile: {
      type: Boolean,
      default: () => false,
    },
    idGroupList: {
      type : Array,
      default: () => []
    },
    dataSearch:{
      Type:Object,
      default:()=>{}
    },
    groupActive:{
      type: Array,
      default:()=> []
    },
  },
  methods: {
    handleCloseExportStatisticsFile() {
      this.selectExport = 1
      this.$emit("close", false);
    },
    // Xuất file
    async handleExportFile() {
      if (this.selectExport === 1) {
        this.showExportStudentNotCompleted = true
      } else if (this.selectExport === 2) {
        this.showExportStudentComplete = true
      } else if (this.selectExport === 3) {
        await this.checkStudentIsNotInGroup()
        if (this.groupActive.length !==0){
          let message = "Các nhóm bị xóa không thể thực hiện tác vụ: <br>"
          let index = 1
          for (let i = 0; i < this.groupActive.length; i++) {
            message =message + index + ". &nbsp" + this.groupActive[i]+ "<br>"
            index++
          }
          message = message + "Vui lòng thực hiện lại!"
          this.$message.error({
            showClose: true,
            message: message,
            dangerouslyUseHTMLString: true,
          });
        }else if (this.listNameStudentNotInGroup.length !== 0){
          let message = "Các nhóm không có học sinh không thể thực hiện tác vụ: <br>"
          let index = 1
          for (let i = 0; i < this.listNameStudentNotInGroup.length; i++) {
            message =message + index + ". &nbsp" + this.listNameStudentNotInGroup[i]+ "<br>"
            index++
          }
          message = message + "Vui lòng thực hiện lại!"
          this.$message.error({
            showClose: true,
            message: message,
            dangerouslyUseHTMLString: true,
          });
        } else {
          this.showExportStudentNotOrder = true
        }
      } else if (this.selectExport === 4) {
        this.showExportStudentCompleteAndNot = true
      }
    },
    async checkStudentIsNotInGroup(){
      const listIdGroup = this.idGroupList.join(`,`)
      await statisticFinanceV2Service.getCheckStudentNotInGroup(listIdGroup).then((response) =>{
        this.listNameStudentNotInGroup = response.data
      })
    },
    closeExportStudentNotCompleted() {
      this.showExportStudentNotCompleted = false
    },
    closeExportStudentComplete() {
      this.showExportStudentComplete = false
    },
    closeExportStudentNotOrder() {
      this.showExportStudentNotOrder = false
    },
    closeExportStudentCompleteAndNot() {
      this.showExportStudentCompleteAndNot = false
    }
  },
}
</script>

<style lang="scss" scoped>
.dialog-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.buttons {
  display: flex;
  justify-content: right;
  text-align: right;
  margin-top: 30px;
}
</style>