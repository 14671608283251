<template>
  <div>
    <el-table
        element-loading-spinner="el-icon-loading"
        :empty-text="$tableEmpty"
        :data="tableData"
        v-loading="loadingDataClass"
        :element-loading-text="$tableLoading"
        element-loading-background="rgba(255,255,255, 0)"
        :header-cell-style="tableHeaderColor"
        @selection-change="handleSelectionChange"
        :max-height="$tableMaxHeight"
        border
        ref="multipleTable"
        :summary-method="getSummaries"
        show-summary
    >
      <el-table-column
          type="selection"
          align="center"
          width="55"
      ></el-table-column>
      <el-table-column
          type="index"
          fixed="left"
          label="STT"
          width="50"
          align="center"
      ></el-table-column>

      <el-table-column label="Tên lớp" prop="className">

      </el-table-column>

      <el-table-column
          align="right"
          label="Thiếu/Thừa tháng trước"
          prop="beforeMoneyTotal"
      >
        <template slot-scope="{ row }">
          {{ row.beforeMoneyTotal | formatCurrency }}
        </template>
      </el-table-column>
      <el-table-column prop="sumMoneyTotal" align="right" label="Phải thu tháng này">
        <template slot-scope="{ row }">
          {{ row.sumMoneyTotal |formatCurrency }}
        </template>
      </el-table-column>
      <el-table-column prop="collectedMoneyTotal" align="right" label="Đã thu">
        <template slot-scope="scope">
          <el-button
              class="click-in-row"
              type="text"
          >
            <span v-if="scope.row.collectedMoneyTotal">{{ scope.row.collectedMoneyTotal | formatCurrency }}</span>
            <span v-else><b>-</b></span>
          </el-button>
        </template>
      </el-table-column>

      <el-table-column prop="cashMoneyTotal" align="right" label="Tiền mặt">
        <template slot-scope="{ row }">
          {{ row.cashMoneyTotal | formatCurrency }}
        </template>
      </el-table-column>
      <el-table-column prop="transferMoneyTotal" align="right" label="Chuyển khoản">
        <template slot-scope="{ row }">
          {{ row.transferMoneyTotal | formatCurrency }}
        </template>
      </el-table-column>
      <el-table-column prop="afterMoneyTotal" align="right" label="Thiếu / Thừa còn lại">
        <template slot-scope="{ row }">
          {{ row.afterMoneyTotal | formatCurrency }}
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 15px; float: right">

      <el-button
          style="margin: 5px"
          class="button-over"
          :loading="loadingButton"
          type="success"
          @click="exportToExcelCurrent()"
      >Xuất file Excel thống kê hiện tại
      </el-button>
      <el-dropdown @command="handleCommandMethod">
        <el-button
            type="success"
            class="button-bottom"
        >
          Tác vụ
          <i class="el-icon-caret-bottom"/>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="exportStatisticsFile"
          >Xuất file theo tiêu chí
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div>
      <ExportStatisticsFile
          :showExportStatisticsFile="showExportStatisticsFile"
          :idClassList="multipleSelection"
          :dataSearch="dataSearch"
          :classActive="classActive"
          @close="closeExportStatisticsFileDialog"
          ref="ExportStatisticsFile"
      />
    </div>
  </div>
</template>

<script>


import ExportStatisticsFile
  from "@/components/FinanceVer2/TuitionStatistics/Class/TableSearchDialog/ExportStatisticsFileDialog.vue";
import {mapGetters} from "vuex";
import {exportToExcel} from "@/commonFuncion/ExportExcelFEOnly";

export default {
  name: 'StatisticTableClass',
  components: {ExportStatisticsFile},
  props: {
    dataSearch: {
      Type: Object,
      default: () => {
      }
    },
    tableData: {
      Type: Array,
      default: () => []
    },
    loadingDataClass: {
      Type: Boolean,
      default: () => false
    }
  },
  watch: {
    tableData: {
      handler() {
        this.$emit('set-loading-table-class', false)
      },
      deep: true
    }
  },
  // mounted() {
  //   console.log(this.tableData);
  // },
  data() {
    return {
      loadingButton: false,
      multipleSelection: [],
      showExportStatisticsFile: false,
      classActive: [],
      exportToExcelData: {
        titleFile: 'THỐNG KÊ HỌC PHÍ THEO LỚP',
        columnHeaders: [
          'STT', 'Tên lớp', 'Thiếu/Thừa tháng trước ', 'Phải thu tháng này',
          'Đã thu', 'Tiền mặt', 'Chuyển khoản', 'Thiếu thừa còn lại'
        ],
        columnWidths: [5, 15, 22, 20, 20, 20, 20, 20], // Chiều rộng của từng cột
        nameFile: 'ThongKeHocPhi'
      },
    }

  },
  created() {
    this.$store.dispatch('schoolStore/fetchDataSchoolData');
  },
  computed: {
    ...mapGetters('schoolStore', ['schoolInfo']),
    completeExportToExcelData() {
      return {
        ...this.exportToExcelData,
        companyInfo: this.schoolInfo,
        dataList: this.mapDataForTitle()
      }
    }
  },
  methods: {
    exportToExcel,
    tableHeaderColor() {
      return "background-color: #78a5e7;color: #fff;font-weight: bold;";
    },
    tableRowStyle({row}) {
      if (row.orderKids == null) {
        return "color: #409EFF";
      } else if (row.orderKids.totalNumber == 0) {
        return "color: #FB9A00";
      } else if (row.orderKids.totalNumber != row.orderKids.enoughNumber) {
        return "color: #FD4803";
      }
    },
    handleCommandMethod(command) {
      if (this.multipleSelection.length === 0) {
        this.$message.error({
          showClose: true,
          message: "Chưa có lớp nào được chọn",
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      if (command === "exportStatisticsFile") {
        this.showExportStatisticsFile = true
      }
      if (command === "exportStatisticsFileNow") {
        this.exportToExcelCurrent();
      }
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = []
      this.classActive = []
      for (let i = 0; i < val.length; i++) {
        this.multipleSelection.push(val[i].idClass)
        if (val[i].delActive === false) {
          this.classActive.push(val[i].className)
        }
      }
    },
    closeExportStatisticsFileDialog() {
      this.showExportStatisticsFile = false
      this.$refs.multipleTable.clearSelection();
    },

    getSummaries(param) {
      const {columns, data} = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 2) {
          sums[index] = "Tổng cộng";
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0).toLocaleString("vi-VN", {
            style: "currency",
            currency: "VND"
          });
        } else {
          sums[index] = '';
        }
      });

      return sums;
    },
    formatVND(number) {
      if (number === null || number === undefined) {
        return 0; // hoặc giá trị mặc định khác tùy ý
      }
      return number.toLocaleString('vi-VN');
    },
    mapDataForTitle() {
      const dataList = this.tableData
      return dataList.map((x, index) => [
        index + 1,
        x.className,
        this.formatVND(x.beforeMoneyTotal),
        this.formatVND(x.sumMoneyTotal),
        this.formatVND(x.collectedMoneyTotal),
        this.formatVND(x.cashMoneyTotal),
        this.formatVND(x.transferMoneyTotal),
        this.formatVND(x.afterMoneyTotal),
      ]);
    },
    calculateTotalMoney(){
      const dataList = this.tableData;
      let a1 = 0;
      let a2 = 0;
      let a3 = 0;
      let a4 = 0;
      let a5 = 0;
      let a6 = 0;
      for (let i = 0; i < dataList.length; i++) {
       a1 += dataList[i].beforeMoneyTotal;
       a2 += dataList[i].sumMoneyTotal;
       a3 += dataList[i].collectedMoneyTotal;
       a4 += dataList[i].cashMoneyTotal;
       a5 += dataList[i].transferMoneyTotal;
       a6 += dataList[i].afterMoneyTotal;
      }
      return ['', 'Tổng cộng', this.formatVND(a1), this.formatVND(a2),this.formatVND(a3), this.formatVND(a4), this.formatVND(a5), this.formatVND(a6)];
    },
    async exportToExcelCurrent() {
      this.loadingButton = true;
      const columnBottoms = this.calculateTotalMoney();
      this.$confirm(
          " Bạn có chắc chắn muốn xuất file hiện tại?",
          "Thông báo!",
          {
            confirmButtonText: "Có",
            cancelButtonText: "Không",
          }
      ).then(async () => {
        await this.exportToExcel(this.completeExportToExcelData,columnBottoms)
      }).finally(() => this.loadingButton = false);
    }
  },
  beforeMount() {
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-table th {
  padding: 2px 0;
}

/deep/ .el-table th.el-table__cell {
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #FFF;
}

/deep/ .el-table__footer-wrapper tbody td.el-table__cell, .el-table__header-wrapper tbody td.el-table__cell {
  font-weight: bold;
  background-color: #F5F7FA;
  color: #0957f4;
}
</style>