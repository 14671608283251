<template>
  <div>
    <section style="height: 2.5rem">
      <statistic-search-class @set-data-class-table="handleClassData"
                              v-if="this.changeTabClass==false"
                              @set-class-data-search="handleDataSearchClass"
                              @set-loading-table-class="handleLoadingClass"/>
      <statistic-search-group
          @set-data-group-table="handleGroupData"
          @set-loading-table-group="handleLoadingGroup"
          @set-group-data-search="handleDataSearchGroup"
          v-else/>

    </section>
    <el-tabs v-model="selectTab" @tab-click="handleTabClick" type="card">
      <el-tab-pane label="Theo lớp" name="first">
        <statistic-table-class
            :dataSearch="dataSearchClass"
            :loading-data-class="loadingTableClass"
            :table-data="tableClassData"
            @set-loading-table-class="handleLoadingClass"/>

      </el-tab-pane>
      <el-tab-pane label="Theo nhóm" name="second">
        <statistic-table-group
            :dataSearch="dataSearchGroup"
            :loading-data-group="loadingTableGroup"
            :table-group-data="tableGroupData"
            @set-loading-table-group="handleLoadingGroup"
        />
      </el-tab-pane>
    </el-tabs>

  </div>
</template>

<script>
import StatisticSearchClass from "@/components/FinanceVer2/TuitionStatistics/Class/StatisticSearchClass.vue";
import StatisticTableClass from "@/components/FinanceVer2/TuitionStatistics/Class/StatisticTableClass.vue";
import StatisticSearchGroup from "@/components/FinanceVer2/TuitionStatistics/Group/StatisticSearchGroup.vue";
import StatisticTableGroup from "@/components/FinanceVer2/TuitionStatistics/Group/StatisticTableGroup.vue";

export default {
  components: {StatisticTableGroup, StatisticSearchGroup, StatisticTableClass, StatisticSearchClass},
  data() {
    return {
      selectTab: 'first',
      tableClassData: [],
      tableGroupData: [],
      changeTabClass: false,
      loadingTableClass: false,
      dataSearchClass: {},
      dataSearchGroup: {},
      loadingTableGroup: false,

    }
  },

  methods: {
    handleDataSearchClass(dataSearch) {
      this.dataSearchClass = dataSearch
    },
    handleDataSearchGroup(dataSearch) {
      this.dataSearchGroup = dataSearch
    },
    handleTabClick(tab) {
      if (tab.label === "Theo nhóm") {
        return this.changeTabClass = true
      } else return this.changeTabClass = false
    },
    handleClassData(tableData) {
      this.tableClassData = tableData
    },
    handleGroupData(tableData) {
      this.tableGroupData = tableData
    },
    handleLoadingClass(value) {
      this.loadingTableClass = value
    },
    handleLoadingGroup(value) {
      this.loadingTableGroup = value
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-tabs__nav-scroll {
  float: right;
}

/deep/ .el-tabs__nav-wrap {
  margin-top: 0px;
  margin-bottom: -15px;
}

/deep/ .el-tabs__nav {
  background: #a0a19ce0;
}

/deep/ .el-tabs__item {
  color: white;
}

/deep/ .el-tabs__item.is-active {
  color: white;
  background: #78a5e7;
}

/deep/ .el-tabs--card > .el-tabs__header {
  border-bottom: none;
}
</style>