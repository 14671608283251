<template>
  <div class="dialog-container">
    <el-dialog
        width="700px"
        style="font-size: 20px"
        title="Xuất danh sách các học sinh chưa hoàn thành hóa đơn"
        :visible.sync="showExportStudentNotCompleted"
        :before-close="handleCloseExportStudentNotCompleted"
        :close-on-click-modal="false"
    >
      <el-form style="margin-top: 20px">
        <el-row>
          <el-form-item label="Chọn thời gian">
            <el-date-picker
                style="width:325px; margin-left: 50px"
                format="MM-yyyy"
                v-model="listMonth"
                type="monthrange"
                align="center"
                unlink-panels
                range-separator="-"
                start-placeholder="Tháng bắt đầu"
                end-placeholder="Tháng kết thúc"
            >
            </el-date-picker>
          </el-form-item>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="Tình trạng hóa đơn">
              <el-checkbox-group v-model="selectExport">
                <el-checkbox label="Chưa đóng 100%" style="margin-left: 20px">Chưa đóng 100%</el-checkbox>
                <el-checkbox label="Chưa đóng đủ" style="margin-left: 52px">Chưa đóng đủ</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="buttons">
        <el-button
            :disabled = "this.selectExport.length === 0"
            :loading="loadingExportFile"
            size="medium"
            type="success"
            @click="handleExportFile()"
        >
          OK
        </el-button>
        <el-button size="medium" type="danger" @click="handleCloseExportStudentNotCompleted">
          <i class="el-icon-circle-close"/> Đóng
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>
import {v4} from "uuid";
import moment from "moment/moment";
import statisticFinanceV2Service from "@/services/Finance_Kids_V2/StatisticFinanceV2Service";
import {handleAndDownloadExcel} from "@/utils/dowloadExcel";

export default {
  name: "ExportStudentNotCompletedForGroup",
  data() {
    return {
      loadingExportFile: false,
      selectExport: [],
      listMonth: [],
    }
  },
  props: {
    showExportStudentNotCompleted: {
      type: Boolean,
      default: () => false,
    },
    idGroupList: {
      type : Array,
      default: () => []
    },
    collectionTurn:{
      type: Number,
      default:()=> 0
    },
  },
  methods: {
    handleCloseExportStudentNotCompleted() {
      this.loadingExportFile = false
      this.listMonth = []
      this.selectExport = []
      this.$emit("close", false);
    },
    // Xuất file
    handleExportFile() {
      this.loadingExportFile = true;
      const guiId = v4();
      const  groupParams = {
        listIdGroup: this.idGroupList.join(','),
        collectionTurn: this.collectionTurn,
        status: this.selectExport.join(','),
        dateStart: moment(this.listMonth[0]).format('YYYY-MM-DD'),
        dateEnd: moment(this.listMonth[1]).format('YYYY-MM-DD'),
        guiId: guiId
      }
      statisticFinanceV2Service.exportExcelStatisticForGroup(groupParams).then((res) =>{
        if (res.data.message === "processing") {
          this.$notify.info({
            title: "Thông báo",
            message: "Dữ liệu đang được xử lý !",
          });
        }
        const maxAttempts = 15; // Số lần kiểm tra tối đa
        let attempts = 0; // Biến đếm số lần đã kiểm tra
        // Call the function again after 5 seconds
        const checkProcess = setInterval(() => {
          statisticFinanceV2Service.exportExcelStatisticForGroup(groupParams).then((res) => {
            // chạy neeus k conf processing
            if (res.data.message === "processing") {
              if (attempts < maxAttempts){
                attempts++
                this.$notify.info({
                  title: "Thông báo",
                  message: "Dữ liệu đang được xử lý !",
                });
                return;
              }else {
                clearInterval(checkProcess)
                this.$notify.error({
                  title: "Thông báo",
                  message: "Quá trình xuất dữ liệu quá lâu. Vui lòng thử lại sau.",
                });
              }
            }
            if(res.data.message === "fail"){
              clearInterval(checkProcess)
              this.$notify.error({
                title: "Thông báo",
                message: "Lỗi không xuất được dữ liệu !",
              });
              return;
            }
            if (res.data.message !== "processing" && res.data.message !== "fail") {
              const fileNameRegex = /complete_(.*).xlsx/;
              const matches = res.data.message.match(fileNameRegex);
              const fileName = matches[1];
              this.$notify.success({
                title: "Thông báo",
                message: "Dữ liệu xuất thành công",
              });
              this.loadingExportFile = false;
              let excelFilename = res.data.message.split('complete_')[1];
              clearInterval(checkProcess);
              return statisticFinanceV2Service
                  .downloadFileExcelStatistic(guiId)
                  .then((res) => {
                    const excelData = res.data;
                    excelFilename = `${fileName}.xlsx`; // Provide a desired filename for the downloaded file
                    handleAndDownloadExcel(excelData, excelFilename);
                  });
            }
          })
              .catch((err) => {
                this.loadingExportFile = false
                return Promise.reject(err);
              });
        }, 5000);
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.buttons {
  display: flex;
  justify-content: right;
  text-align: right;
  margin-top: 20px;
}

/deep/ .el-dialog__title {
  font-size: 22px;
  color: #606266;
}
</style>