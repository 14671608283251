<template>
  <div class="dialog-container">
    <el-dialog
        width="600px"
        title="Xuất file thống kê"
        :visible.sync="showExportStatisticsFile"
        :before-close="handleCloseExportStatisticsFile"
        :close-on-click-modal="false"
    >
      <div>
        <el-radio-group v-model="selectExport">
          <el-radio :label="1" style="margin-top: 20px">Xuất danh sách các học sinh chưa hoàn thành hóa đơn</el-radio>
          <el-radio :label="2" style="margin-top: 30px">Xuất danh sách các học sinh đã hoàn thành hóa đơn</el-radio>
          <el-radio :label="3" style="margin-top: 30px">Xuất danh sách các học sinh chưa có hóa đơn</el-radio>
          <el-radio :label="4" style="margin-top: 30px">Xuất danh sách các học sinh với tất cả trạng thái</el-radio>
        </el-radio-group>
      </div>
      <div class="buttons">
        <el-button
            :loading="loadingExportFile"
            size="medium"
            type="success"
            @click="handleExportFile()"
        >
          OK
        </el-button>
        <el-button size="medium" type="danger" @click="handleCloseExportStatisticsFile">
          <i class="el-icon-circle-close"/> Đóng
        </el-button>
      </div>
    </el-dialog>
    <ExportStudentNotCompleted
        :showExportStudentNotCompleted="showExportStudentNotCompleted"
        :idClassList = "idClassList"
        :collectionTurn = "dataSearch.collectionTurn"
        @close="closeExportStudentNotCompleted"
        ref="ExportStudentNotCompleted"
    />
    <ExportStudentComplete
        :showExportStudentComplete="showExportStudentComplete"
        :idClassList = "idClassList"
        :collectionTurn = "dataSearch.collectionTurn"
        @close="closeExportStudentComplete"
        ref="ExportStudentComplete"
    />
    <ExportStudentNotOrder
        :showExportStudentNotOrder="showExportStudentNotOrder"
        :idClassList = "idClassList"
        :collectionTurn = "dataSearch.collectionTurn"
        @close="closeExportStudentNotOrder"
        ref="ExportStudentComplete"
    />
    <ExportStudentCompleteAndNot
        :showExportStudentCompleteAndNot="showExportStudentCompleteAndNot"
        :idClassList = "idClassList"
        :collectionTurn = "dataSearch.collectionTurn"
        @close="closeExportStudentCompleteAndNot"
        ref="ExportStudentCompleteAndNot"
    />
  </div>
</template>

<script>
import ExportStudentNotCompleted
  from "@/components/FinanceVer2/TuitionStatistics/Class/TableSearchDialog/ExportStudentNotCompletedDialog.vue";
import ExportStudentComplete
  from "@/components/FinanceVer2/TuitionStatistics/Class/TableSearchDialog/ExportStudentCompleteDialog.vue";
import ExportStudentNotOrder
  from "@/components/FinanceVer2/TuitionStatistics/Class/TableSearchDialog/ExportStudentNotOrderDialog.vue";
import statisticFinanceV2Service from "@/services/Finance_Kids_V2/StatisticFinanceV2Service";
import ExportStudentCompleteAndNot
  from "@/components/FinanceVer2/TuitionStatistics/Class/TableSearchDialog/ExportStudentCompleteAndNotDialog.vue";

export default {
  name: "ExportStatisticsFile",
  components: {ExportStudentNotOrder, ExportStudentComplete, ExportStudentNotCompleted , ExportStudentCompleteAndNot},
  data() {
    return {
      selectExport: 1,
      loadingExportFile: false,
      showExportStudentNotCompleted: false,
      showExportStudentComplete: false,
      showExportStudentNotOrder: false,
      showExportStudentCompleteAndNot: false,
      listNameStudentNotInClass: []
    }
  },
  props: {
    showExportStatisticsFile: {
      type: Boolean,
      default: () => false,
    },
    idClassList: {
      type : Array,
      default: () => []
    },
    dataSearch:{
      Type:Object,
      default:()=>{}
    },
    classActive:{
      type: Array,
      default:()=> []
    },
  },
  methods: {
    handleCloseExportStatisticsFile() {
      this.selectExport = 1
      this.$emit("close", false);
    },
    // Xuất file
     async handleExportFile() {
      if (this.selectExport === 1) {
        this.showExportStudentNotCompleted = true
      } else if (this.selectExport === 2) {
        this.showExportStudentComplete = true
      } else if (this.selectExport === 3){
        await this.checkStudentIsNotInClass()
        if (this.classActive.length !==0){
          let message = "Các lớp bị xóa không thể thực hiện tác vụ: <br>"
          let index = 1
          for (let i = 0; i < this.classActive.length; i++) {
            message =message + index + ". &nbsp" + this.classActive[i]+ "<br>"
            index++
          }
          message = message + "Vui lòng thực hiện lại!"
          this.$message.error({
            showClose: true,
            message: message,
            dangerouslyUseHTMLString: true,
          });
        }else if (this.listNameStudentNotInClass.length !== 0){
          let message = "Các lớp không có học sinh không thể thực hiện tác vụ: <br>"
          let index = 1
          for (let i = 0; i < this.listNameStudentNotInClass.length; i++) {
            message =message + index + ". &nbsp" + this.listNameStudentNotInClass[i]+ "<br>"
            index++
          }
          message = message + "Vui lòng thực hiện lại!"
          this.$message.error({
            showClose: true,
            message: message,
            dangerouslyUseHTMLString: true,
          });
        }
        else {
          this.showExportStudentNotOrder = true
        }
      } else if (this.selectExport === 4) {
        this.showExportStudentCompleteAndNot = true
      }
    },
    async checkStudentIsNotInClass(){
      const listIdClass = this.idClassList.join(`,`)
     await statisticFinanceV2Service.getCheckStudentNotInClass(listIdClass).then((response) =>{
        this.listNameStudentNotInClass = response.data
      })
    },
    closeExportStudentNotCompleted() {
      this.showExportStudentNotCompleted = false
    },
    closeExportStudentComplete() {
      this.showExportStudentComplete = false
    },
    closeExportStudentNotOrder() {
      this.showExportStudentNotOrder = false
    },
    closeExportStudentCompleteAndNot() {
      this.showExportStudentCompleteAndNot = false
    }
  },
}
</script>

<style lang="scss" scoped>
.dialog-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.buttons {
  display: flex;
  justify-content: right;
  text-align: right;
  margin-top: 30px;
}
</style>