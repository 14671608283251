<template>
  <div class="search-table">
    <el-date-picker
        format="MM-yyyy"
        style="width: 200px"
        v-model="dateChoose"
        type="monthrange"
        align="right"
        unlink-panels
        @change="handleChangeTime"
        range-separator="-"
        start-placeholder="Tháng bắt đầu"
        :clearable="false"
        end-placeholder="Tháng kết thúc">
    </el-date-picker>

    <!-- chọn trạng học cho học sinh -->
    <el-select
        @change="handleChangeKidStatus"
        style="width: 140px"
        class="input-common"
        v-model="dataSearch.kidStatus"
        placeholder="Trạng thái học"
        clearable
    >
      <el-option
          v-for="item in kidStatusListExcel"
          :key="item.key"
          :value="item.key"
          :label="item.value"
      ></el-option>
    </el-select>

    <!-- chọn trạng thái -->
    <el-select
        @change="handleChangeGrade"
        style="width: 160px"
        class="input-common"
        v-model="dataSearch.idGrade"
        placeholder="Chọn khối"
        clearable
    >
      <el-option
          v-for="item in listGrade"
          :key="item.id"
          :value="item.id"
          :label="item.gradeName"
      ></el-option>
    </el-select>
    <!--       chọn trạng thái -->
    <el-select
        style="width: 180px"
        v-model="dataSearch.transactionEntry"
        placeholder="Trạng thái kết chuyển"
        @change="handleChangeMoneyStatus"
        clearable
    >
      <el-option
          v-for="(item,index) in  statusOrderList"
          :key="index"
          :value="item.value"
          :label="item.label"
      ></el-option>
    </el-select>


    <!--       Trạng thái của lớp (Đã xóa hoặc còn tồn tại) -->
    <el-select
        style="width: 180px"
        v-model="dataSearch.statusClass"
        placeholder="Trạng thái của lớp"
        @change="handleChangeClassStatus"
        clearable
    >
      <el-option
          v-for="(item,index) in statusClassList"
          :key="index"
          :value="item.value"
          :label="item.label"
      ></el-option>
    </el-select>

    <el-input
        @clear="handleClearClassName"
        clearable
        class="input-common input-class"
        style="width: 240px"
        placeholder="Nhập tên lớp"
        v-model="className"
        @keyup.enter.native="handleChangeClassName"
    >
      <el-button
          slot="append"
          icon="el-icon-search"
          @click="handleSearchResultByClass"
      ></el-button>
    </el-input>
    <el-select
        @change="handleChangeCollectionTurn"
        style="width: 100px"
        class="input-common"
        v-model="dataSearch.collectionTurn"
        placeholder="Đợt thu"
        clearable
    >
      <el-option
          v-for="item in collectionTurn"
          :key="item"
          :value="item"
          :label="`Đợt ${item}`"
      ></el-option>
    </el-select>

  </div>

</template>
<script>
import moment from 'moment'
import statisticFinanceV2Service from "@/services/Finance_Kids_V2/StatisticFinanceV2Service";
import GradeService from "@/services/GradeService";
import {kidStatusListExcel} from "@/constant/listCommon";

export default {
  name: 'StatisticSearchClass',
  computed: {},
  data() {
    return {
      dateChoose: [],
      monthAndYear: moment().format('M-yyyy'),
      //select
      listGrade: [],
      statusOrderList: [
        {label: 'Không chuyển', value: 'MONEY_WALLET'},
        {label: 'Sang tháng sau', value: 'MONEY_MONTH'}
      ],
      statusClassList: [
        {label: 'Tồn tại', value: true},
        {label: 'Đã xóa', value: false}
      ],
      collectionTurn: 0,
      className: '',
      dataSearch: {
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        idGrade: '',
        transactionEntry: null,
        className: null,
        collectionTurn: 1,
        statusClass: true,
        kidStatus: null
      },
      kidStatusListExcel
    }
  },
  created() {
    this.dateChoose = [this.dataSearch.startDate, this.dataSearch.endDate]
    this.setLoadingTableClass()
    this.fetchingInitData()
  },
  methods: {
    setLoadingTableClass() {
      this.$emit('set-loading-table-class', true)
    },
    fetchingInitData() {
      this.getListGrade()
    },
    getListGrade() {
      GradeService.getGradeInPrinciple()
          .then((resp) => {
            this.listGrade = resp?.data?.data ?? [];
            this.dataSearch.idGrade = this.listGrade[0].id;
          })
          .then(() => {
            this.getClassCollectionTurn()

          })
          .catch((err) => {
            console.log(err);
          });
    },
    getClassCollectionTurn() {
      const collectionParam = {
        idGrade: this.dataSearch.idGrade,
        className: this.dataSearch.className,
        startDate: this.dataSearch.startDate,
        endDate: this.dataSearch.endDate,
      }
      return statisticFinanceV2Service.getStatisticCollectionTurn(collectionParam).then((res) => {
        if (res.data == 0) {
          this.dataSearch.collectionTurn = null
          this.collectionTurn = null
        } else {
          this.collectionTurn = res.data
        }
      }).then(() => {
        this.callDataSearch()
      })
    },
    callDataSearch() {
      if (this.dataSearch.transactionEntry === "") {
        this.dataSearch.transactionEntry = null
      }
      this.dataSearch.startDate = moment(this.dateChoose[0]).format('YYYY-MM-DD');
      this.dataSearch.endDate = moment(this.dateChoose[1]).format('YYYY-MM-DD');
      this.$emit('set-class-data-search', this.dataSearch)
      return statisticFinanceV2Service.getAllFilterStatistic(this.dataSearch).then((res) => {
        this.$emit('set-data-class-table', res?.data ?? [])
        if (res.data.length == 0) {
          this.$emit('set-loading-table-class', false)
        }
      })
          .catch((er) => {
            console.log(er)
          })
    },

    handleChangeTime(){
      this.setLoadingTableClass()
      this.callDataSearch()
      this.getClassCollectionTurn()
    },

    handleChangeGrade() {
      this.setLoadingTableClass()
      this.callDataSearch()
      this.getClassCollectionTurn()

    },
    handleChangeCollectionTurn() {
      this.setLoadingTableClass()
      this.callDataSearch()
    },
    handleSearchResultByClass() {
      this.setLoadingTableClass()
      this.dataSearch.className = this.className
      this.callDataSearch()
      this.getClassCollectionTurn()
    },
    handleChangeClassName() {
      this.setLoadingTableClass()
      this.dataSearch.className = this.className
      this.callDataSearch()
      this.getClassCollectionTurn()
    },
    handleClearClassName() {
      this.setLoadingTableClass()
      this.dataSearch.className = this.className
      this.callDataSearch()
      this.getClassCollectionTurn()
    },
    handleChangeMoneyStatus() {
      this.setLoadingTableClass()
      this.callDataSearch()
      this.getClassCollectionTurn()
    },
    handleChangeClassStatus() {
      this.setLoadingTableClass()
      this.callDataSearch()
      this.getClassCollectionTurn()
    },
    handleChangeKidStatus() {
      if (this.dataSearch.kidStatus === "") {
        this.dataSearch.kidStatus = null
      }
      this.setLoadingTableClass()
      this.callDataSearch()
      this.getClassCollectionTurn()
    }
  }
}
</script>

<style lang="scss" scoped>
.search-table {
  margin-bottom: 20px;
  z-index: 1;
  display: flex;
  width: 100%;
  gap: 0.3rem;
  flex-wrap: wrap;
}
</style>
