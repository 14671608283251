var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "section",
        { staticStyle: { height: "2.5rem" } },
        [
          this.changeTabClass == false
            ? _c("statistic-search-class", {
                on: {
                  "set-data-class-table": _vm.handleClassData,
                  "set-class-data-search": _vm.handleDataSearchClass,
                  "set-loading-table-class": _vm.handleLoadingClass,
                },
              })
            : _c("statistic-search-group", {
                on: {
                  "set-data-group-table": _vm.handleGroupData,
                  "set-loading-table-group": _vm.handleLoadingGroup,
                  "set-group-data-search": _vm.handleDataSearchGroup,
                },
              }),
        ],
        1
      ),
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          on: { "tab-click": _vm.handleTabClick },
          model: {
            value: _vm.selectTab,
            callback: function ($$v) {
              _vm.selectTab = $$v
            },
            expression: "selectTab",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Theo lớp", name: "first" } },
            [
              _c("statistic-table-class", {
                attrs: {
                  dataSearch: _vm.dataSearchClass,
                  "loading-data-class": _vm.loadingTableClass,
                  "table-data": _vm.tableClassData,
                },
                on: { "set-loading-table-class": _vm.handleLoadingClass },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "Theo nhóm", name: "second" } },
            [
              _c("statistic-table-group", {
                attrs: {
                  dataSearch: _vm.dataSearchGroup,
                  "loading-data-group": _vm.loadingTableGroup,
                  "table-group-data": _vm.tableGroupData,
                },
                on: { "set-loading-table-group": _vm.handleLoadingGroup },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }