<template>
  <div class="search-table">
    <el-date-picker
        format="MM-yyyy"
        style="width: 200px"
        @change="handleChangeTime"
        v-model="dateChoose"
        type="monthrange"
        align="right"
        unlink-panels
        range-separator="-"
        :clearable="false"
        start-placeholder="Ngày bắt đầu"
        end-placeholder="Ngày kết thúc">
    </el-date-picker>

    <!-- chọn trạng học cho học sinh -->
    <el-select
        @change="handleChangeKidStatus"
        style="width: 140px"
        class="input-common"
        v-model="dataSearch.kidStatus"
        placeholder="Trạng thái học"
        clearable
    >
      <el-option
          v-for="item in kidStatusListExcel"
          :key="item.key"
          :value="item.key"
          :label="item.value"
      ></el-option>
    </el-select>

    <!--       chọn trạng thái -->
    <el-select
        style="width: 180px"
        v-model="dataSearch.transactionEntry"
        placeholder="Trạng thái kết chuyển"
        @change="handleChangeMoneyStatus"
        clearable
    >
      <el-option
          v-for="(item,index) in  statusOrderList"
          :key="index"
          :value="item.value"
          :label="item.label"
      ></el-option>
    </el-select>

    <!--       Trạng thái của nhóm (Đã xóa hoặc còn tồn tại) -->
    <el-select
        style="width: 180px"
        v-model="dataSearch.statusGroup"
        placeholder="Trạng thái của nhóm"
        @change="handleChangeGroupStatus"
        clearable
    >
      <el-option
          v-for="(item,index) in  statusGroupList"
          :key="index"
          :value="item.value"
          :label="item.label"
      ></el-option>
    </el-select>

    <el-input
        @clear="handleClearGroupName"
        clearable
        class="input-common input-class"
        style="width: 240px"
        placeholder="Nhập tên nhóm"
        v-model="groupName"
        @keyup.enter.native="handleChangeGroupName"
    >
      <el-button
          slot="append"
          icon="el-icon-search"
          @click="handleSearchResultByClass"
      ></el-button>
    </el-input>
    <el-select
        @change="handleChangeCollectionTurn"
        style="width: 100px"
        class="input-common"
        v-model="dataSearch.collectionTurn"
        placeholder="Đợt thu"
        clearable
    >
      <el-option
          v-for="item in collectionTurn"
          :key="item"
          :value="item"
          :label="`Đợt ${item}`"
      ></el-option>
    </el-select>

  </div>

</template>

<script>
import moment from "moment";
import statisticFinanceV2Service from "@/services/Finance_Kids_V2/StatisticFinanceV2Service";
import {kidStatusListExcel} from "@/constant/listCommon";

export default {
  name: 'StatisticSearchGroup',
  data() {
    return {
      dateChoose: [],
      monthAndYear: moment().format('M-yyyy'),
      //select
      listGrade: [],
      statusOrderList: [
        {label: 'Không chuyển', value: 'MONEY_WALLET'},
        {label: 'Sang tháng sau', value: 'MONEY_MONTH'}
      ],
      statusGroupList: [
        {label: 'Tồn tại', value: true},
        {label: 'Đã xóa', value: false}
      ],
      collectionTurn: 0,
      groupName: '',
      dataSearch: {
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        transactionEntry: null,
        groupName: null,
        collectionTurn: 1,
        statusGroup: true,
        kidStatus: null
      },
      kidStatusListExcel,

    }
  },
  created() {
    this.dateChoose = [this.dataSearch.startDate, this.dataSearch.endDate]
    this.setLoadingTableGroup()
    this.fetchingInitData()
  },

  methods: {
    setLoadingTableGroup() {
      this.$emit('set-loading-table-group', true)
    },
    fetchingInitData() {
      this.getGroupCollectionTurn()
    },
    getGroupCollectionTurn() {
      const collectionParam = {
        groupName: this.dataSearch.groupName,
        startDate: this.dataSearch.startDate,
        endDate: this.dataSearch.endDate,
      }
      return statisticFinanceV2Service.getStatisticOfCollectionTurnForGroup(collectionParam).then((res) => {
        if (res.data == 0) {
          this.dataSearch.collectionTurn = null
          this.collectionTurn = null
        } else {
          this.collectionTurn = res.data
          this.dataSearch.collectionTurn = res.data
        }
      }).then(() => {
        this.callDataSearch()
      })
    },
    callDataSearch() {
      if (this.dataSearch.transactionEntry === "") {
        this.dataSearch.transactionEntry = null
      }
      this.dataSearch.startDate = moment(this.dateChoose[0]).format('YYYY-MM-DD');
      this.dataSearch.endDate = moment(this.dateChoose[1]).format('YYYY-MM-DD');
      this.$emit('set-group-data-search', this.dataSearch)
      return statisticFinanceV2Service.getAllFilterStatisticForGroup(this.dataSearch).then((res) => {
        this.$emit('set-data-group-table', res?.data ?? [])
        if (res.data.length == 0) {
          this.$emit('set-loading-table-group', false)
        }
      })
          .catch((er) => {
            console.log(er)
          })
    },

    handleChangeTime(){
      this.setLoadingTableGroup()
      this.callDataSearch()
      this.getGroupCollectionTurn()
    },
    handleChangeGrade() {
      this.setLoadingTableGroup()
      this.callDataSearch()
      this.getGroupCollectionTurn()
    },
    handleChangeCollectionTurn() {
      this.setLoadingTableGroup()

      this.callDataSearch()
    },
    handleSearchResultByClass() {
      this.setLoadingTableGroup()

      this.dataSearch.groupName = this.groupName
      this.callDataSearch()
      this.getGroupCollectionTurn()
    },
    handleChangeGroupName() {
      this.setLoadingTableGroup()
      this.dataSearch.groupName = this.groupName
      this.callDataSearch()
      this.getGroupCollectionTurn()
    },
    handleClearGroupName() {
      this.setLoadingTableGroup()

      this.dataSearch.groupName = this.groupName
      this.callDataSearch()
      this.getGroupCollectionTurn()
    },
    handleChangeMoneyStatus() {
      this.setLoadingTableGroup()
      this.callDataSearch()
      this.getGroupCollectionTurn()
    },
    handleChangeGroupStatus() {
      this.setLoadingTableGroup()
      this.callDataSearch()
      this.getGroupCollectionTurn()
    },

    handleChangeKidStatus() {
      if (this.dataSearch.kidStatus === "") {
        this.dataSearch.kidStatus = null
      }
      this.setLoadingTableGroup()
      this.callDataSearch()
      this.getGroupCollectionTurn()
    }

  }
}
</script>

<style scoped>
.search-table {
  margin-bottom: 20px;
  z-index: 1;
  display: flex;
  width: 100%;
  gap: 0.3rem;
  flex-wrap: wrap;
}
</style>
